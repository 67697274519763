import {
	useState,
	useEffect,
	useRef,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Image,
	useColorModeValue,
	Spinner,
} from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil'
import LazyLoad from 'react-lazy-load'
import Slider from 'react-slick'

import {
	scrubBlockState,
	staticModeState,
	confModeState,
	scrubbingState,
	landscapeOrientationState,
	circlesBreakpointState,
	type ArtBlock,
	artBlocksState,
	artistsState,
	blockEmbedState,
} from '../../../state'
import {
	BITCOIN_ORANGE,
	TIMECHAIN_CALENDAR_STATIC1_URL,
} from '../../../constants'
import { BlockEmbeds } from './BlockEmbeds'
import { getBreakpointValue, sortByProperty } from '../../../utils'
import { SidebarSectionTitle } from '../../layout/sidebar/SidebarSection'

interface BlockEmbedTargetProps {
	// eslint-disable-next-line no-unused-vars
	onSearchBlock: (x: number) => void
}

export const BlockEmbedTarget = ({ onSearchBlock }: BlockEmbedTargetProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const staticMode = useRecoilValue(staticModeState)
	const scrubbing = useRecoilValue(scrubbingState)
	const confMode = useRecoilValue(confModeState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const artBlocks = useRecoilValue(artBlocksState)
	const artists = useRecoilValue(artistsState)
	const [embedAttribution, setEmbedAttribution] = useState('')
	const [sliderLoading, setSliderLoading] = useState(true)
	const [blockEmbed, setBlockEmbed] = useRecoilState(blockEmbedState)
	const targetRef = useRef(null)
	const [relatedArt, setRelatedArt] = useState<ArtBlock[]>([])

	const blockFontSize = getBreakpointValue({ base: '13px', md: '14px' }, circlesBreakpoint)
	const galleryThumbSize = getBreakpointValue({ base: 60, md: 80 }, circlesBreakpoint)
	const galleryShowCount = getBreakpointValue({ base: 5, lg: 5 }, circlesBreakpoint) as number
	const galleryThumbSizeBorderString = `${Number(galleryThumbSize) + 2}px`
	const color = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	// FIND EMBED ON THIS BLOCK
	const artist = artists.find((x) => x.name === embedAttribution)
	const artistPath = artist ? artist.path : ''
	const showGallery = !confMode && process.env.REACT_APP_SELF_HOSTED !== 'true'
	const showBlockEmbeds = staticMode
		&& showGallery
		&& blockEmbed
		&& !scrubbing
	const responsivePy = landscapeOrientation ? 7 : 4
	const autoplay = relatedArt.length > galleryShowCount
	const slideshowWidth = autoplay ? '100%' : 'auto'

	const handleGoToEmbedBlock = (embedBlock: number) => {
		setSliderLoading(true)
		setBlockEmbed(null)
		onSearchBlock(embedBlock)
	}

	const handleSliderOnInit = () => {
		setSliderLoading(false)
	}

	useEffect(() => {
		const newBlockEmbed = artBlocks.find((embed) => embed.b === scrubBlock.height) as ArtBlock
		
		if (newBlockEmbed) {
			setBlockEmbed(newBlockEmbed)
		}

		return () => setBlockEmbed(null)
	}, [scrubBlock])

	useEffect(() => {
		if (blockEmbed && blockEmbed.a) {
			const relatedPieces = artBlocks.filter((x) => x.a === blockEmbed.a)
			const sortedRelatedPieces = sortByProperty(relatedPieces, 'b', true)

			setEmbedAttribution(blockEmbed.a)
			setRelatedArt(sortedRelatedPieces)
		}

		return () => {
			setRelatedArt([])
			setEmbedAttribution('')
		}
	}, [blockEmbed])

	if (!showBlockEmbeds) {
		return null
	}

	return (
		<Flex
			className="block-gallery-embed-wrap"
			direction="column"
			justify="center"
			py={responsivePy}
		>
			<Flex
				className="tc-block-embed-target"
				ref={targetRef}
				justify="center"
				align="center"
				w="100%"
				minH="150px"
				zIndex="2100"
				overflow="visible"
				animation="blurIn 0.42s ease-in"
				mb={8}
			>
				<BlockEmbeds />
			</Flex>

			{relatedArt.length > 0 && (
				<Box
					w={slideshowWidth}
					pos="relative"
					mb={10}
				>
					<SidebarSectionTitle title={`${embedAttribution}++`} />
					

					{sliderLoading && (
						<Spinner color={color} />
					)}

					<Box w="100%" mt={-1}>
						<Slider
							onInit={handleSliderOnInit}
							pauseOnHover={true}
							autoplay={autoplay}
							autoplaySpeed={4000}
							speed={2500}
							infinite={autoplay}
							slidesToShow={galleryShowCount}
							slidesToScroll={1}
							swipeToSlide={true}
							nextArrow={<></>}
							prevArrow={<></>}
						>
							{relatedArt.map((piece) => {
								const {
									b: pieceBlock,
									g: pieceThumbUrl,
								} = piece
								const title = `Block ${pieceBlock} by ${embedAttribution}`
								const gThumbUrl = `${TIMECHAIN_CALENDAR_STATIC1_URL}/${artistPath}/${pieceThumbUrl}`
								const thisBlock = pieceBlock === scrubBlock.height
								const relatedColor = thisBlock ? 'green100' : color

								return (
									<Flex
										key={pieceBlock}
										onClick={thisBlock ? undefined : () => handleGoToEmbedBlock(pieceBlock)}
										direction="column"
										role="group"
										cursor={thisBlock ? 'default' : 'cursor'}
									>
										<Text
											fontSize={blockFontSize}
											fontWeight={thisBlock ? 'bold' : 'normal'}
											lineHeight="none"
											color={relatedColor}
											mb={1}
											transition="all 0.45s ease"
											_groupHover={thisBlock
												? undefined
												: {
													color: BITCOIN_ORANGE,
												}
											}
										>
											{pieceBlock}
										</Text>

										<Box
											w={galleryThumbSizeBorderString}
											h={galleryThumbSizeBorderString}
											borderRadius="md"
											borderWidth={1}
											borderColor={thisBlock ? 'green100' : undefined}
											transition="all 0.45s ease"
											_groupHover={thisBlock
												? undefined
												: {
													borderColor: BITCOIN_ORANGE,
												}
											}
										>
											<LazyLoad height={galleryThumbSize} offset={400}>
												<Image
													title={title}
													flex={0}
													boxSize={`${galleryThumbSize}px`}
													htmlHeight={`${galleryThumbSize}px`}
													htmlWidth={`${galleryThumbSize}px`}
													src={gThumbUrl}
													objectFit="cover"
													borderRadius="md"
												/>
											</LazyLoad>
										</Box>
									</Flex>
								)
							})}
						</Slider>
					</Box>
				</Box>
			)}
		</Flex>
	)
}