import {
	useEffect,
	useState,
	useMemo,
	useCallback,
} from 'react'
import {
	useRecoilValue,
	useRecoilState,
	useSetRecoilState,
} from 'recoil'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import mempoolJS from '@mempool/mempool.js'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { Flex, useDisclosure } from '@chakra-ui/react'
import type { TimePickerValue } from 'react-time-picker'
import { format, compareAsc } from 'date-fns'

import {
	appInitializedState,
	appRenderedState,
	confModeState,
	blockState,
	scrubBlockState,
	futureBlockState,
	futureBlockDateState,
	futureBlockTimeState,
	connectionState,
	poolNameState,
	scrubUsdRatiosState,
	scrubPriceState,
	scrubbingState,
	pastDateSearchingState,
	showBlockDropState,
	staticModeState,
	showBlockRippleState,
	contentHeightState,
	circlesWrapWidthState,
	landscapeOrientationState,
	halvingVideoPlayingState,
	loadingBlockHeightState,
	shouldSetAppToBlockPathState,
	circlesBreakpointState,
	past24IntroCompleteState,
} from './state'
import {
	BlockDetailsModal,
	GMAlert,
	BlockHeight,
	BlockHeightDrop,
	DatePickerModal,
	TCSearch,
	TCLiveModeButton,
	CalendarAlert,
	CalendarAlertButton,
	defaultCalAlert,
	DropSound,
	GongSound,
	DoorbellSound,
	BellSound,
	WhooshSound,
} from './components/features'
import {
	CirclesSquareWrapV3,
	BlocksToHalving,
	BlocksToDiff,
	PastBlocksCircle,
	CornerNW,
	CornerNE,
	CornerSW,
	CornerSE,
	TCSidebarV5,
	BlockTimer,
} from './components/layout'
import { TitleAndStatus } from './components/static'
import { FutureSearchModal } from './components/features/calendar/future-search'
import {
	Upper,
	GenesisInscriptionV3,
	Loading,
	Lower,
} from './components/features/calendar'
import { BlocksToHalvingRipple } from './components/layout/circles/BlockToHalvingRipple'
import { setCalAlert, setCalAlertDismissed } from './components/features/calendar-alert/calAlertReducer'
import { useAppSelector, useAppDispatch } from './hooks'
import {
	AUDIO_SOUNDS,
	MEMPOOL_SPACE_API_V1_URL,
	BLOCKCHAIN_INFO_BASE_URL,
	CORS_PROXY_BASE_URL,
	QRSNAP_BASE_URL,
	MIN_LANDSCAPE_CIRCLE_HEIGHT,
	GM_CONFIG,
	type BitcoinDateAlert,
} from './constants'
import { setUserLocale } from './components/features/settings/settingsReducer'
import { ACTIVE_USER_LOCALES, type UserLocale } from './lang/messages'
import './theme/datepicker.css'
import './theme/timepicker.css'
import {
	updateTime,
	getRandomItem,
	getBreakpointValue,
} from './utils'
import { TCCalendarControls } from './components/features/calendar-controls/TCCalendarControls'
import { SponsorsSlideshow, HalvingVideo } from './components/features'
import {
	setGmDismissed,
	setGmDismissedDate,
	setGmTodayDate,
	setGmTodayMessage
} from './components/features/calendar-alert/gmAlertReducer'
import { PercentageSupply } from './components/layout/circles/PercentageSupply'

export type Connection = {
	readyState: number
}

const TCAppV3 = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const intl = useIntl()
	const [shouldSetAppToBlockPath, setShouldSetAppToBlockPath] = useRecoilState(shouldSetAppToBlockPathState)
	const [blockPathChangeDone, setBlockPathChangeDone] = useState(false)
	const [blockPath, setBlockPath] = useState<number | undefined>(undefined)
	const [calendarAlert, setCalendarAlert] = useState<BitcoinDateAlert>(defaultCalAlert.alert)
	const [gmToday, setGmToday] = useState('')
	const [todayHour, setTodayHour] = useState('')
	const setLoadingBlockHeight = useSetRecoilState(loadingBlockHeightState)

	// @ts-ignore
	const { audioActive, audioSound, userLocale } = useAppSelector(({ settings }) => settings)
	const { alert, dismissed } = useAppSelector(({ calAlert }) => calAlert)
	const { showSlideshow} = useAppSelector(({ confModeSponsors }) => confModeSponsors)

	const blockPathHeight = useParams<'blockHeight'>().blockHeight
	const userLocalePath = useParams<'userLocalePath'>().userLocalePath

	const connection = useRecoilValue(connectionState)
	const block = useRecoilValue(blockState)
	const { extras } = block

	const [scrubBlock, setScrubBlock] = useRecoilState(scrubBlockState)

	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const setScrubPrice = useSetRecoilState(scrubPriceState)
	const setScrubUsdRatios = useSetRecoilState(scrubUsdRatiosState)
	const [scrubbing, setScrubbing] = useRecoilState(scrubbingState)
	const [staticMode, setStaticMode] = useRecoilState(staticModeState)
	const [appRendered, setAppRendered] = useRecoilState(appRenderedState)
	const [appInitialized, setAppInitalized] = useRecoilState(appInitializedState)
	const setFutureBlock = useSetRecoilState(futureBlockState)
	const setFutureBlockDate = useSetRecoilState(futureBlockDateState)
	const setFutureBlockTime = useSetRecoilState(futureBlockTimeState)
	const showBlockDrop = useRecoilValue(showBlockDropState)
	const [showBlockRipple, setShowBlockRipple] = useRecoilState(showBlockRippleState)
	const circleWrapWidth = useRecoilValue(circlesWrapWidthState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const setContentHeight = useSetRecoilState(contentHeightState)
	const setPoolName = useSetRecoilState(poolNameState)
	const setPastDateSearching = useSetRecoilState(pastDateSearchingState)
	const setHalvingVideoPlaying = useSetRecoilState(halvingVideoPlayingState)
	const confMode = useRecoilValue(confModeState)
	const [past24IntroComplete, setPast24IntroComplete] = useRecoilState(past24IntroCompleteState)

	const [playDropSound, setPlayDropSound] = useState(false)
	const [playGongSound, setPlayGongSound] = useState(false)
	const [playDoorbellSound, setPlayDoorbellSound] = useState(false)
	const [playBellSound, setPlayBellSound] = useState(false)
	const [playWhooshSound, setPlayWhooshSound] = useState(false)

	const HEADER_FOOTER_HEIGHT = getBreakpointValue({ base: 30, sm: 40 }, circlesBreakpoint) as number
	const flex = landscapeOrientation ? '1 0 auto' : '0 0 auto'
	const interfaceLoadingOpacity = circleWrapWidth === 0 ? 0 : 1

	const responsiveAlign = landscapeOrientation
		? 'center'
		: 'flex-start'

	const readyState = {
		readyState: connection?.readyState
	} as Connection

	const {
		isOpen: isOpenBlockDetailsModal,
		onOpen: onOpenBlockDetailsModal,
		onClose: onCloseBlockDetailsModal,
	} = useDisclosure()
	const {
		onOpen: onOpenFutureSearch,
		isOpen: isOpenFutureSearch,
		onClose: onCloseFutureSearch,
	} = useDisclosure()
	const {
		onOpen: onOpenDatePickerModal,
		isOpen: isOpenDatePickerModal,
		onClose: onCloseDatePickerModal,
	} = useDisclosure()
	const {
		isOpen: isOpenGmAlert,
		onClose: onCloseGmAlert,
		onOpen: onOpenGmAlert,
	} = useDisclosure()
	const {
		onOpen: onOpenCalendarAlert,
		isOpen: isOpenCalendarAlert,
		onClose: onCloseCalendarAlert,
	} = useDisclosure()
	const {
		onOpen: onOpenHalvingVideo,
		isOpen: isOpenHalvingVideo,
		onClose: onCloseHalvingVideo,
	} = useDisclosure()

	// GM ALERTS
	const [gmInitialized, setGmInitialized] = useState(false)
	const todayDate = new Date()
	const todayDayString = format(todayDate, 'yyyy-MM-dd')
	const todayHourString = format(todayDate, 'k')
	const nowHour = Number(todayHour)
	const { hour, durationHours } = GM_CONFIG
	const isActiveHour = (nowHour >= hour) && (nowHour < (hour + durationHours))
	const {
		gmDismissed,
		gmDismissedDate,
		gmTodayDate,
		gmTodayMessage,
	} = useAppSelector(({ gmAlert }) => gmAlert)
	const storedDateMatchesToday = gmToday === gmTodayDate
	const dismissedDateMatchesToday = gmDismissedDate === gmTodayDate
	const isDismissedToday = storedDateMatchesToday
		&& gmDismissed
		&& dismissedDateMatchesToday
	const shouldDisplayGmAlert =
		!isDismissedToday // has not been dismissed today
		&& isActiveHour // currently within configured alert hour
		&& !confMode
		&& appInitialized

	// CALENDAR ALERTS
	const BITCOIN_DATES: BitcoinDateAlert[] = useMemo(
		() => [
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.genesis.title' }),
				date: '01-03',
				year: 2009,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.genesis' }),
				link: 'https://mempool.space/block/000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f',
				bDay: true,
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.v1_released.title' }),
				date: '01-08',
				year: 2009,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.v1_released' }),
				link: 'https://satoshi.nakamotoinstitute.org/emails/cryptography/16/',
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.running.title' }),
				date: '01-10',
				year: 2009,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.running' }),
				link: 'https://twitter.com/halfin/status/1110302988?s=20&t=Rei7O-SVssOA7ZLqNwdjvQ'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.first_tx.title' }),
				date: '01-11',
				year: 2009,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.first_tx' }),
				link: 'https://mempool.space/block/00000000d1145790a8694403d4063f323d499e655c83426834d4ce2f8dd4a2ee'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.silk_road.title' }),
				date: '01-27',
				year: 2011,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.silk_road' }),
				link: 'https://freeross.org/'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.gox.title' }),
				date: '02-24',
				year: 2014,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.gox' }),
				link: 'https://en.wikipedia.org/wiki/Mt._Gox'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.manifesto.title' }),
				date: '03-09',
				year: 1993,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.manifesto' }),
				link: 'https://www.activism.net/cypherpunk/manifesto.html'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.rollback.title' }),
				date: '03-11',
				year: 2013,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.rollback' }),
				link: 'https://bitcoin.org/en/alert/2013-03-11-chain-fork'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.moscow_time.title' }),
				date: '03-25',
				year: 2021,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.moscow_time' }),
				link: 'https://twitter.com/VickerySec/status/1375128455134646279'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.satoshi_bday.title' }),
				date: '04-05',
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.satoshi_bday' }),
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.hal_finney_bday.title' }),
				date: '05-04',
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.hal_finney_bday' }),
				link: 'https://en.wikipedia.org/wiki/Hal_Finney_(computer_scientist)'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.pizza.title' }),
				date: '05-22',
				year: 2010,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.pizza' }),
				link: 'https://bitcoinmagazine.com/culture/bitcoin-pizza-day-a-day-of-celebration'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.ny_agreement.title' }),
				date: '05-23',
				year: 2017,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.ny_agreement' }),
				link: 'https://bitcoinmagazine.com/tags/new-york-agreement'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.independence_day.title' }),
				date: '08-01',
				year: 2017,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.independence_day' }),
				link: 'https://blog.bitfinex.com/education/the-fork-wars-what-is-bitcoin-independence-day'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.overflow.title' }),
				date: '08-15',
				year: 2010,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.overflow' }),
				link: 'https://en.bitcoin.it/wiki/Value_overflow_incident'
			},
			{
				label: 'Bitcoin Infinity Day',
				date: '08-21',
				year: 2021,
				description: 'This emblematic date was first highlighted through the writings of author Knut Svalnholm. Celebrate the finite and infinite of ∞/21M.',
				link: 'https://www.knutsvanholm.com'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.whitepaper.title' }),
				date: '10-31',
				year: 2008,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.whitepaper' }),
				link: 'https://bitcoin.org/bitcoin.pdf'
			},
			// {
			// 	label: intl.formatMessage({ id: 'alerts.bitcoin_dates.coldcard.title' }),
			// 	date: '12-14',
			// 	year: 2017,
			// 	description: intl.formatMessage({ id: 'alerts.bitcoin_dates.coldcard' }),
			// 	link: 'https://coldcard.com/'
			// },
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.hodl.title' }),
				date: '12-18',
				year: 2013,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.hodl' }),
				link: 'https://bitcointalk.org/index.php?topic=375643.0'
			},
			{
				label: intl.formatMessage({ id: 'alerts.bitcoin_dates.da.title' }),
				date: '12-30',
				year: 2009,
				description: intl.formatMessage({ id: 'alerts.bitcoin_dates.da' }),
				link: 'https://bitcointalk.org/index.php?topic=43.0'
			},

			// {
			// 	label: intl.formatMessage({ id: 'alerts.bitcoin_dates.genesis.title' }),
			// 	date: '02-03',
			// 	year: 2009,
			// 	description: intl.formatMessage({ id: 'alerts.bitcoin_dates.genesis' }),
			// 	link: 'https://mempool.space/block/000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f',
			// },
		],
		[userLocale],
	)

	const getBitcoinDateAlert = (dateValue: string) => {
		const dateObject = BITCOIN_DATES.find((bitcoinDate) => bitcoinDate.date === dateValue)
		if (dateObject) return dateObject
		return false
	}

	const today = new Date()
	const todayDay = format(today, 'dd')
	const todayMonth = format(today, 'MM')
	const todayBitcoinDatesKey = `${todayMonth}-${todayDay}`
	const todayBitcoinDateAlert = getBitcoinDateAlert(todayBitcoinDatesKey)

	const storageMatchesToday = alert && alert.date === todayBitcoinDatesKey
	const isCalAlertDisplayed = !isOpenCalendarAlert
		&& todayBitcoinDateAlert
		&& !dismissed

	const handleOpenCalendarAlert = () => {
		if (todayBitcoinDateAlert) {
			setCalendarAlert(todayBitcoinDateAlert)
			onOpenCalendarAlert()
		}
	}
	const handleCloseCalAlert = () => {
		onCloseCalendarAlert()
		dispatch(setCalAlert(calendarAlert))
		dispatch(setCalAlertDismissed(true))
	}

	const handleChangeBlockRoute = useCallback((newBlock: number) => {
		const locale = userLocale || 'en'
		if (newBlock === block.height) {
			navigate(`/${locale}`)
		} else {
			navigate(`block/${newBlock}`)
		}
	}, [
		block,
		userLocale,
	])

	const handleChangeStaticMode = useCallback((newBlock: number) => {
		if (block.height === 0) return
		if (newBlock < block.height && !staticMode) return setStaticMode(true)
		if (newBlock === block.height) return setStaticMode(false)
		return
	}, [
		block,
		staticMode,
	])

	const handleScrubChange = useCallback((newBlock: number) => {
		const blockUpdate = {
			...block,
			height: newBlock
		}
		handleChangeStaticMode(newBlock)
		setScrubBlock(blockUpdate)
	}, [block])

	const handleSearchFutureBlock = useCallback((newBlock: number, settingBlockPath = false) => {
		setFutureBlock(newBlock)
		onOpenFutureSearch()
		navigate(`block/${newBlock}`)
		setLoadingBlockHeight(false)
		if (settingBlockPath) setBlockPathChangeDone(true)
	}, [])

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleGetBlockV1 = async (blockId: string, callbackFunc: any, secondCallbackFunc?: any) => {
		const baseUrl = `${MEMPOOL_SPACE_API_V1_URL}/block/`
		await axios.get(`${baseUrl}${blockId}`)
			.then((res) => {
				callbackFunc(res.data)
				secondCallbackFunc && secondCallbackFunc(res.data)
				setPastDateSearching(false)
			}) // TODO: add catch and handle error
	}

	const handleGetPriceByBlock = (block: number) => {
		const qrSnapRequest = `${QRSNAP_BASE_URL}/block/${block}/price`
		axios.get(qrSnapRequest)
			.then((res) => {
				const { price, usd_r } = res.data.result
				setScrubPrice(price)
				setScrubUsdRatios(usd_r)
			}) // TODO: add catch and handle error
	}

	const handleScrubChangeEnd = useCallback(async (newBlock: number, settingBlockPath = false) => {
		const {
			bitcoin: { blocks },
		} = mempoolJS()
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const getBlocks: any = await blocks.getBlocks({ start_height: newBlock })
		const hash = await blocks.getBlockHeight({ height: getBlocks[0].height })

		handleGetBlockV1(hash, setScrubBlock)
		handleChangeStaticMode(newBlock)
		handleGetPriceByBlock(newBlock)
		handleChangeBlockRoute(newBlock)
		setPast24IntroComplete(true)
		setTimeout(() => {
			setLoadingBlockHeight(false)
			setScrubbing(false)
		}, 1000)
		if (settingBlockPath) {
			setBlockPathChangeDone(true)
		}
	}, [
		handleChangeBlockRoute,
		handleChangeStaticMode,
		handleGetPriceByBlock,
		setScrubBlock,
		setScrubbing,
	])

	const handleSearchBlock = useCallback((
		newBlock: number,
		settingBlockPath = false,
		last24Block = false,
	) => {
		setLoadingBlockHeight(true)
		setStaticMode(true)
		if (!last24Block) setScrubbing(true)
		handleScrubChangeEnd(newBlock, settingBlockPath)
	}, [])

	const handleSearchBlockByDate = (date: Date) => {
		const timestamp = date.getTime()
		const blockchainInfoRequest = `${BLOCKCHAIN_INFO_BASE_URL}/blocks/${timestamp}?format=json`
		const proxyRequest = `${CORS_PROXY_BASE_URL}?${encodeURIComponent(blockchainInfoRequest)}`

		setPastDateSearching(true)
		setScrubbing(true)
		setStaticMode(true)
		setLoadingBlockHeight(true)
		axios.get(proxyRequest)
			.then((res) => {
				const resBlock = res.data[0].height
				handleScrubChangeEnd(resBlock)
			}) // TODO: add catch and handle error
	}

	const handlePastOrFuture = (date: Date, time: TimePickerValue) => {
		const rightNow = new Date()

		const dateTime = updateTime(date, time)

		// compare date/time to right now
		const isPast = compareAsc(dateTime, rightNow) !== 1

		// if date/time prior to now handle past block search
		if (isPast) {
			handleSearchBlockByDate(date)
		}
		// else open FutureSearchModal, passing date/time in
		if (!isPast) {
			setFutureBlockDate(date)
			setFutureBlockTime(time)
			onOpenFutureSearch()
		}
	}

	const handleNavigateHome = useCallback(() => {
		navigate(`/${userLocale}`)
	}, [userLocale])

	const handleCloseFutureSearchModal = () => {
		setFutureBlock(null)
		setFutureBlockDate(null)
		setFutureBlockTime(null)
		onCloseFutureSearch()
		handleNavigateHome()
	}

	const handleCloseGmAlert = () => {
		console.info('[gm] dismiss')
		dispatch(setGmDismissedDate(gmTodayDate))
		dispatch(setGmDismissed(true))
		onCloseGmAlert()
	}

	const handleOpenHalvingVideo = () => {
		onOpenHalvingVideo()
		setHalvingVideoPlaying(true)
	}

	const handleCloseHalvingVideo = () => {
		onCloseHalvingVideo()
		setHalvingVideoPlaying(false)
	}

	// Document Title
	useEffect(() => {
		if (block) {
			document.title = `${intl.formatMessage({ id: 'future.block' }).toUpperCase()}: ${block.height}`
		}
	}, [block])

	// Clear past calendar alert
	useEffect(() => {
		if (alert && !storageMatchesToday) {
			setCalendarAlert(defaultCalAlert.alert)
			dispatch(setCalAlert(null))
			dispatch(setCalAlertDismissed(false))
		}
	}, [alert, storageMatchesToday])

	// GM Alert Initialize Today & Hour
	useEffect(() => {
		if (!todayHour) {
			console.info('[gm] today set')
			setGmToday(todayDayString)
			setTodayHour(todayHourString)
			dispatch(setGmTodayDate(todayDayString))
		}
	}, [])

	// GM Alert Message Set
	useEffect(() => {
		const GM_ALERTS_MAP = ['alerts.gm_alerts.gm1', 'alerts.gm_alerts.gm2', 'alerts.gm_alerts.gm3', 'alerts.gm_alerts.gm4', 'alerts.gm_alerts.gm5', 'alerts.gm_alerts.gm6', 'alerts.gm_alerts.gm7', 'alerts.gm_alerts.gm8', 'alerts.gm_alerts.gm9', 'alerts.gm_alerts.gm10', 'alerts.gm_alerts.gm11', 'alerts.gm_alerts.gm12', 'alerts.gm_alerts.gm13', 'alerts.gm_alerts.gm14', 'alerts.gm_alerts.gm15', 'alerts.gm_alerts.gm16', 'alerts.gm_alerts.gm17', 'alerts.gm_alerts.gm18', 'alerts.gm_alerts.gm19', 'alerts.gm_alerts.gm20', 'alerts.gm_alerts.gm21', 'alerts.gm_alerts.gm22', 'alerts.gm_alerts.gm23', 'alerts.gm_alerts.gm24', 'alerts.gm_alerts.gm25', 'alerts.gm_alerts.gm26', 'alerts.gm_alerts.gm27', 'alerts.gm_alerts.gm28', 'alerts.gm_alerts.gm29', 'alerts.gm_alerts.gm30', 'alerts.gm_alerts.gm31', 'alerts.gm_alerts.gm32', 'alerts.gm_alerts.gm33', 'alerts.gm_alerts.gm34', 'alerts.gm_alerts.gm35', 'alerts.gm_alerts.gm36', 'alerts.gm_alerts.gm37']
		const getGmMessage = () => getRandomItem(GM_ALERTS_MAP)

		const handleInitMessage = (todayDateString: string, dismissed: boolean) => {
			const newMessage = getGmMessage()
			console.info('[gm] new message')
			dispatch(setGmTodayMessage(newMessage))
			dispatch(setGmTodayDate(todayDateString))
			dispatch(setGmDismissed(dismissed))
		}

		if (!gmInitialized && shouldDisplayGmAlert) {
			if (dismissedDateMatchesToday) {
				console.info('[gm] storage empty, initialized')
				setGmInitialized(true)
				return
			}
			if (!dismissedDateMatchesToday) {
				console.info('[gm] date mismatch, initializing')
				handleInitMessage(gmToday, false)
				setGmInitialized(true)
				return
			}
		}
	}, [
		gmInitialized,
		shouldDisplayGmAlert,
		dismissedDateMatchesToday,
	])

	// Calendar alert
	useEffect(() => {
		const handleInitCalAlert = () => {
			if (todayBitcoinDateAlert) {
				setCalendarAlert(todayBitcoinDateAlert)
				dispatch(setCalAlert(todayBitcoinDateAlert))
				dispatch(setCalAlertDismissed(false))
				onOpenCalendarAlert()
			}
		}

		// has stored alert && an alert should show today
		if (alert && todayBitcoinDateAlert) {
			// today's alert has NOT been dismissed
			if (!dismissed) {
				setCalendarAlert(alert)
				onOpenCalendarAlert()
				return
			}
		}

		// no storage
		if (!alert) {
			if (isCalAlertDisplayed) {
				handleInitCalAlert()
			}
			return
		}
	}, [
		isCalAlertDisplayed,
		dismissed,
		todayBitcoinDatesKey,
		todayBitcoinDateAlert,
		isOpenCalendarAlert,
		alert,
	])

	// Date search url
	useEffect(() => {
		if (location.pathname.includes('/date')) {
			onOpenDatePickerModal()
		}
	}, [location])

	// Audio
	useEffect(() => {
		const handleAudioPlayback = () => {
			const selectedSound = audioSound || AUDIO_SOUNDS.drop
			if (audioActive) {
				switch (selectedSound) {
					case AUDIO_SOUNDS.drop:
						setPlayDropSound(true)
						return
					case AUDIO_SOUNDS.whoosh:
						setPlayWhooshSound(true)
						return
					case AUDIO_SOUNDS.gong:
						setTimeout(() => setPlayGongSound(true), 290)
						return
					case AUDIO_SOUNDS.bell:
						setTimeout(() => setPlayBellSound(true), 230)
						return
					case AUDIO_SOUNDS.doorbell:
						setTimeout(() => setPlayDoorbellSound(true), 320)
						return
					default:
						return
				}
			}
		}
		if (showBlockDrop) {
			handleAudioPlayback()
		}
	}, [
		showBlockDrop,
		audioSound,
		audioActive,
	])
	
	useEffect(() => {
		const headerAndFooter = HEADER_FOOTER_HEIGHT * 2
		const standardHeight = (window.innerHeight - headerAndFooter)
		const MIN_CONTENT_HEIGHT = MIN_LANDSCAPE_CIRCLE_HEIGHT + 30
		const contentHeight = landscapeOrientation
			? standardHeight < MIN_CONTENT_HEIGHT
				? MIN_CONTENT_HEIGHT
				: standardHeight
			: standardHeight

		setContentHeight(contentHeight)
	}, [circleWrapWidth, landscapeOrientation])

	// MINING POOL HANDLER (SCRUB BLOCK)
	useEffect(() => {
		if (scrubBlock.extras.pool.name) {
			setPoolName(scrubBlock.extras.pool.name)
			return
		}
		setPoolName('')
	}, [scrubBlock])

	// MINING POOL HANDLER (BLOCK)
	useEffect(() => {
		// @ts-ignore
		if (extras.pool.name) {
			// @ts-ignore
			setPoolName(extras.pool.name)
			return
		}
		setPoolName('')
	}, [extras])

	// UPDATE SCRUB BLOCK
	useEffect(() => {
		setScrubBlock(block)
	}, [block])

	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// INITIALIZATION EFFECTS /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //

	// 1) Render flag set to true on load
	useEffect(() => {
		setAppRendered(true)
	}, [])

	// 2) If no block path on load initialize as normal
	useEffect(() => {
		const defaultLoadCondition = appRendered
			&& !appInitialized
			&& blockPathHeight === undefined
			&& block.height !== 0

		if (defaultLoadCondition) {
			console.info('[app] init default')
			setScrubBlock(block)
			setAppInitalized(true)
		}
	}, [
		appRendered,
		appInitialized,
		blockPathHeight,
		block,
	])

	// 3) On initial render check for block path in url
	useEffect(() => {
		// If there is a valid value in url, trigger the app to load past/future block
		const shouldSetBlock = appRendered
			&& !appInitialized
			&& blockPathHeight !== undefined

		if (shouldSetBlock) {
			console.info(`[app] init load block path ${blockPathHeight}`)
			setBlockPath(Number(blockPathHeight))
			setShouldSetAppToBlockPath(true)
			setAppInitalized(true)
		}
	}, [
		appRendered,
		appInitialized,
		blockPathHeight,
	])

	// 4) Update app UI according to block path
	useEffect(() => {
		if (
			blockPath !== undefined
			&& block.height > 0
			&& shouldSetAppToBlockPath
		) {
			if (blockPath === block.height) {
				setBlockPathChangeDone(true)
				handleChangeBlockRoute(blockPath)
				return
			}
			if (blockPath > block.height) {
				handleSearchFutureBlock(blockPath, true)
			} else {
				handleSearchBlock(blockPath, true)
			}
		}
	}, [
		block,
		blockPath,
		shouldSetAppToBlockPath,
	])

	// 5) Clean up block path load flags
	useEffect(() => {
		if (blockPathChangeDone) {
			setBlockPath(undefined)
			setShouldSetAppToBlockPath(false)
		}
	}, [blockPathChangeDone])

	// If user locale in url path make sure to set the same in local storage
	useEffect(() => {
		let safeUserLocale
		const condition = appRendered
			&& !appInitialized
			&& userLocalePath
			&& userLocalePath !== userLocale

		if (condition) {
			if (userLocale === undefined || !userLocale) {
				console.info('[locale] undefined - set locale to EN')
				safeUserLocale = 'en'
			}
			if (userLocalePath && !ACTIVE_USER_LOCALES.includes(userLocalePath)) {
				console.info('[locale] not allowed - set locale to EN')
				safeUserLocale = 'en'
			}
			if (userLocalePath && ACTIVE_USER_LOCALES.includes(userLocalePath)) {
				safeUserLocale = userLocalePath
				console.info('[locale] path detected', userLocalePath)
			}

			dispatch(setUserLocale(safeUserLocale as UserLocale))
		}
	}, [
		appRendered,
		appInitialized,
		userLocalePath,
		userLocale,
	])

	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// END INITIALIZATION EFFECTS /\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //

	if (!appInitialized || circleWrapWidth === 0) {
		return (
			<Loading />
		)
	}

	return (
		<>
			<Flex
				className="tc-circles-and-controls"
				opacity={interfaceLoadingOpacity}
				flex={flex}
				minW={circleWrapWidth}
				direction="column"
				justify={responsiveAlign}
				align="center"
			>
				<CirclesSquareWrapV3>

					{scrubBlock.height === 0 && <GenesisInscriptionV3 />}

					<CornerNW />
					<CornerNE />
					<CornerSW onOpenDatePickerModal={onOpenDatePickerModal} />
					<CornerSE onOpenDatePickerModal={onOpenDatePickerModal} />

					{circleWrapWidth !== 0 && (
						<>
							<PercentageSupply />

							<BlocksToHalving />

							<BlocksToDiff />

							{!scrubbing && (
								<PastBlocksCircle
									onSearchBlockHeight={(
										value: number,
										setBlockPath: boolean,
										last24Block: boolean,
									) => handleSearchBlock(value, setBlockPath, last24Block)}
								/>
							)}
						</>
					)}

					<TitleAndStatus connection={readyState} /> 

					<Upper />

					<BlockHeight
						onSearchBlockHeight={(value: number) => handleSearchBlock(value)}
						onSearchFutureBlock={handleSearchFutureBlock}
						onOpenBlockDetailsModal={onOpenBlockDetailsModal}
						onScrubChangeEnd={handleScrubChangeEnd}
						setScrubValue={handleScrubChange}
					/>
					
					<Lower>
						<BlockTimer />
					</Lower>

					{shouldDisplayGmAlert && (
						<GMAlert
							message={gmTodayMessage}
							isOpen={isOpenGmAlert}
							onOpen={onOpenGmAlert}
							onClose={handleCloseGmAlert}
						/>
					)}

					<TCSearch
						onOpenDatePickerModal={onOpenDatePickerModal}
						handleScrubChange={handleScrubChange}
						handleScrubChangeEnd={(value: number) => handleScrubChangeEnd(value)}
					/>

					{isOpenCalendarAlert && past24IntroComplete && (
						<CalendarAlert
							alert={calendarAlert}
							isOpen={isOpenCalendarAlert}
							onClose={handleCloseCalAlert}
						/>
					)}

					{todayBitcoinDateAlert && (
						<CalendarAlertButton
							onClick={handleOpenCalendarAlert}
							isAlertOpen={isOpenCalendarAlert}
						/>
					)}

					<TCLiveModeButton
						onScrubChangeEnd={handleScrubChangeEnd}
						setScrubValue={handleScrubChange}
					/>

					{showBlockDrop && !staticMode && (
						<BlockHeightDrop />
					)}

					{showBlockRipple && !staticMode && (
						<BlocksToHalvingRipple setShowBlockRipple={setShowBlockRipple} />
					)}					
				</CirclesSquareWrapV3>

				<TCCalendarControls
					handleScrubChange={(value: number) => handleScrubChange(value)}
					setScrubbing={() => setScrubbing(true)}
					handleScrubChangeEnd={(value: number) => handleScrubChangeEnd(value)}
				/>
			</Flex>

			{!confMode && (
				<TCSidebarV5
					onSearchBlock={handleSearchBlock}
					onScrubChangeEnd={handleScrubChangeEnd}
					setScrubValue={handleScrubChange}
					onSearchFutureBlock={handleSearchFutureBlock}
					onOpenBlockDetailsModal={onOpenBlockDetailsModal}
					onOpenHalvingVideo={handleOpenHalvingVideo}
					calAlertOpen={isOpenCalendarAlert}
				/>
			)}

			{confMode && showSlideshow && (
				<>
					<SponsorsSlideshow leftSide={true} />
					<SponsorsSlideshow leftSide={false} />
				</>

			)}

			<HalvingVideo
				isOpen={isOpenHalvingVideo}
				onClose={handleCloseHalvingVideo}
			/>

			<DatePickerModal
				isOpen={isOpenDatePickerModal}
				onClose={onCloseDatePickerModal}
				onSearchBlockByDate={handlePastOrFuture}
			/>

			<FutureSearchModal
				isOpen={isOpenFutureSearch}
				onClose={handleCloseFutureSearchModal}
				height={block.height}
			/>

			<BlockDetailsModal
				isOpen={isOpenBlockDetailsModal}
				onClose={onCloseBlockDetailsModal}
			/>

			{audioActive && audioSound === AUDIO_SOUNDS.drop && (
				<DropSound playSound={playDropSound} setPlaySound={setPlayDropSound} />
			)}
			{audioActive && audioSound === AUDIO_SOUNDS.gong && (
				<GongSound playSound={playGongSound} setPlaySound={setPlayGongSound} />
			)}
			{audioActive && audioSound === AUDIO_SOUNDS.doorbell && (
				<DoorbellSound playSound={playDoorbellSound} setPlaySound={setPlayDoorbellSound} />
			)}
			{audioActive && audioSound === AUDIO_SOUNDS.bell && (
				<BellSound playSound={playBellSound} setPlaySound={setPlayBellSound} />
			)}
			{audioActive && audioSound === AUDIO_SOUNDS.whoosh && (
				<WhooshSound playSound={playWhooshSound} setPlaySound={setPlayWhooshSound} />
			)}
		</>
	)
}

export default TCAppV3
